import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Grid, TextField, FormGroup, Button } from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import alertify from "alertifyjs";
import appConfig from "../../config/appConfig";
import { ContactSupportOutlined } from "@material-ui/icons";
import { Container, Row, Form} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo from "../../assets/istanbul.png";
import { bindActionCreators } from "redux";
import * as loginActions from "../../store/actions/loginActions";
import * as changePasswordActions from "../../store/actions/changePasswordActions";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import container from "../../ioc/IocContainer";
import IAuthService from "../../interfaces/ServiceInterfaces/IAuthService";
import TYPES from "../../ioc/types";

const authService = container.get<IAuthService>(TYPES.IAuthService);

const IsLoggedIn = () => {
  return authService.isLoggedIn();
}

function UpdatePassword(props: any) {

  const [user, setUser] = useState({ id: 0, nameSurname: "", username: "", email:"", phone:"" });
  const isLoggedIn = IsLoggedIn();
  const history = useHistory();

  const logOut = () => {
    props.actions.logout();
    history.push('/login');
  };

  useEffect(() => {
    if (props.userContext.id > 0) {
      getUser();
    }
  }, [props.userContext.id]);

  const getUser = () => {
    axios.get(appConfig.baseApiUrl + "users/GetUser").then((resJson) => {
      setUser(resJson.data);
    });
  };

  if (isLoggedIn === false)
  {
    return <Redirect to="/login" />;
  }
  if (props.isChangedPassword === true) {
   
    return <Redirect to="/home" />;
  }
  return (
    
    <div className="login-main">
      <Container className="login-main-container">
        <Row className="justify-content-md-center">
          <Formik
            initialValues={{
              id: user.id,
              nameSurname: user.nameSurname,
              userName: user.username,
              email: user.email,
              phone:  user.phone,
              password: "",
              passwordConfirm: "",
            }}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize
            validate={(values) => {


            const errors = {} as any;

            if (!values.password)
              errors.password = "Lütfen sifre giriniz!"
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = "Girilen şifreler aynı olmalıdır";
            }

            return errors;
          }}
          onSubmit={(values, actions) => {
            axios
              .post(appConfig.baseApiUrl + "users/UpdatePassword", values)
              .then((resJson) => {

                if (resJson.data.responseCode === 200) {
                  alertify.success("İşlem Başarılı!");
                  getUser();
                  props.actions.setIsChangedPassword(true);
                  history.push('/home');
                }
                else{
                    alertify.error(resJson.data.responseMessage)
                }
                getUser();
              })
              .catch((error) => {
                alertify.error(error.response.data.responseMessage)
              });
              ;
          }}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit} style={{ maxWidth: 250 }}>
                    
          <Form>
              <Image src={logo} fluid style={{width:"300px"}} />
              <FormGroup>
                <TextField
                  name="password"
                  type="password"
                  label="Yeni Şifre"
                  value={formProps.values.password}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.password && {error:true,helperText:formProps.errors.password})}
                />
                <TextField
                  name="passwordConfirm"
                  type="password"
                  label="Yeni Şifre Onayla"
                  value={formProps.values.passwordConfirm}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                />
                {formProps.errors.passwordConfirm &&
                  formProps.touched.passwordConfirm ? (
                    <div style={{ color: "red" }}>
                      {formProps.errors.passwordConfirm}
                    </div>
                  ) : null}
                <Button color="primary" type="submit">
                  Güncelle
                </Button>
                <Button color="secondary" onClick={() => logOut()}>
                Çıkış
                </Button>
              </FormGroup>
              </Form>
    
            </form>
          )}
        </Formik>
        </Row>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userContext: state.loginReducer,
    isLoading: state.loadingReducer,
    isChangedPassword : state.changePasswordReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logout: bindActionCreators(loginActions.logout, dispatch),
      setIsChangedPassword: bindActionCreators(changePasswordActions.setIsChangedPassword, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
