export const USER_CONTEXT = "userContext";

// Roles Constants
export const ROLE_ADMIN = "Admin";
export const ROLE_USER = "User";
// If someone has this role, they can view the balance of accounts
export const ROLE_ACCOUNTANCY = "Accountancy";
// If someone has this role, they can create new customer
export const ROLE_CREATE_CUSTOMER = "CreateCustomer";
// If someone has this role, they can edit customer information
export const ROLE_EDIT_CUSTOMER = "EditCustomer";
// If someone has this role, they can see customer information
export const ROLE_SHOW_CUSTOMER_LIST = "ShowCustomer";
// If someone has this role, they can save new customer's groups information
export const ROLE_SAVE_CUSTOMER_GROUP = "SaveCustomerGroup";
// If someone has this role, they can see customer's groups information
export const ROLE_SHOW_CUSTOMER_GROUP = "ShowCustomerGroup";
// If someone has this role, they can see customer's accounts information
export const ROLE_SHOW_CUSTOMER_ACCOUNTS = "ShowCustomerAccounts";
// If someone has this role, they can transfer with exchange rate 
export const ROLE_EXCHANGE_TRANSFER_WITH_RATE = "ExchangeTransferWithRate";
// If someone has this role, they can make monthly account closure.
export const ROLE_MAKE_MONTH_END_PROCESS = "MakeMonthEndProcess";
// If someone has this role, they can add daily profit
export const ROLE_ADD_DAILY_PROFIT = "AddDailyProfit";
// If someone has this role, they can add shop's profit
export const ROLE_ADD_SHOP_PROFIT = "AddShopProfit";
// If someone has this role, they can get the customer's transaction history.
export const ROLE_GET_CUSTOMER_TRANSACTION_HISTORY = "GetCustomerTransactionHistory";
// If someone has this role, they can get the customer's groups's transaction history.
export const ROLE_GET_GROUP_SUMMARY_REPORT = "GetGroupSummaryReport";
// If someone has this role, they can get all transaction history by date.
export const ROLE_GET_GENERAL_TRANSACTION_HISTORY = "GetGeneralTransactionHistory";
// If someone has this role, they can generate sms report.
export const ROLE_GENERATE_SMS_REPORT = "GenerateSmsReport";

