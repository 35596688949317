import { injectable } from "inversify";
import "reflect-metadata";
import IAuthService from "../interfaces/ServiceInterfaces/IAuthService";
import * as consts from "../common/consts";
import axios from "axios";
import appConfig from '../config/appConfig';

const customAxios = axios.create({
  withCredentials: true,
   headers: {'Access-Control-Allow-Origin': '*', 
   'Access-Control-Allow-Credentials':true,
   'Content-Type': 'application/json'}
});

@injectable()
export default class AuthService implements IAuthService {
  public isChangedPassword(): boolean {
    var storage = localStorage.getItem(consts.USER_CONTEXT);
    if (storage !== null) {
      var user = JSON.parse(storage);
      return user.IsChangedPassword;
    } else return false;
  }
  login2FA(code:any): Promise<Response> {
    return customAxios.post(appConfig.baseApiUrl + "auth/login2fa", code) 
    .then((response) => {
      return response;
      })
      .catch((error) => {
        return error;
      });
  }
  login(user: any): Promise<Response> {
    return customAxios.post(appConfig.baseApiUrl + "auth/login",user)
    .then((response) => {
      return response;
      })
      .catch((error) => {
        return error;
      });
  }
  public isLoggedIn(): boolean {
    var storage = localStorage.getItem(consts.USER_CONTEXT);
    if (storage !== null) {
      return true;
    } else return false;
  }
  public hasCreateCustomerRole():boolean{
    var storage = localStorage.getItem(consts.USER_CONTEXT);
    
    if (storage === null) {
      return false;
    } 
    else {
      var user = JSON.parse(storage);
      if ( user.Roles.includes(consts.ROLE_CREATE_CUSTOMER) ||  user.Roles.includes(consts.ROLE_ADMIN) )
        return true;
      else 
        return false;
    }
  }
  
  public hasExchangeTransferWithRateRole():boolean{
    var storage = localStorage.getItem(consts.USER_CONTEXT);
    
    if (storage === null) {
      return false;
    } 
    else {
      var user = JSON.parse(storage);
      if ( user.Roles.includes(consts.ROLE_EXCHANGE_TRANSFER_WITH_RATE) ||  user.Roles.includes(consts.ROLE_ADMIN) )
        return true;
      else 
        return false;
    }
  }

  public checkUserHasRoles(anyRoles):boolean{
    var storage = localStorage.getItem(consts.USER_CONTEXT);
    if (storage === null) {
      return false;
    } 
    else {
      var user = JSON.parse(storage);
      return anyRoles.some(r=> user.Roles.includes(r));
    }
  }
}
