import React, { Component } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo from "../../assets/istanbul.png";
import { bindActionCreators } from "redux";
import * as loginActions from "../../store/actions/loginActions";
import * as changePasswordActions from "../../store/actions/changePasswordActions";
import { connect } from "react-redux";
import alertify from "alertifyjs";
import { Redirect } from "react-router-dom";
import appConfig from "../../config/appConfig";
import container from "../../ioc/IocContainer";
import IAuthService from "../../interfaces/ServiceInterfaces/IAuthService";
import TYPES from "../../ioc/types";
import jwt_decode from "jwt-decode";
import axios from "axios";
import SendCode from "./SendCode";

class AuthenticateTwoFactor extends Component<any, any> {

  authService = container.get<IAuthService>(TYPES.IAuthService);
  _isMounted = false;
  state = {
    code: "",
    isLoading: false,
    isLoggedIn: false,
    resendCodeError: false,
    resendCodeToggle: true,
    resendCodeTimeStr: "",
    resendCodeTime: 150
  };
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  async authenticate2fa() {
    this.setState({ isLoading: true });
    await this.authService
      .login2FA(this.state)
      .then((response: any) => {
        if (response.toString().includes("Error")) {
          alertify.error(response.response.data.ErrorMessage);
        } else {
          var data = response.data.data;
          var accessToken = data.accessToken;
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
            var claims = jwt_decode<any>(accessToken);

            var userContext = JSON.parse(claims.userContext);
            this.props.actions.login2fa(userContext);
            this.props.actions.setIsChangedPassword(userContext.IsChangedPassword);
            alertify.success("Giriş Başarılı");
          }
          else {
            alertify.error("Bir hata aldın!");
          }
        }
        if (this._isMounted) this.setState({ isLoggedIn: true });
      })
      .catch((error) => {
        alertify.error(error.toString());
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  //  getCancelButton() {
  //   return (
  //     <div className="d-flex justify-content-center">
  //         &nbsp; <Link to="/">Hesabın zaten var mı?</Link>
  //     </div>
  //   );
  //}

  sendCodeButton() {
    if (!this.state.isLoading)
      return (
        <Button variant="primary" type="button" onClick={() => this.authenticate2fa()}>
          Gönder
        </Button>
      );
    else
      return (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Gönder
        </Button>
      );
  }

  SetTrueErrorResendCode(){
    this.setState({ resendCodeError: true });
  }
 
  ReSendCode() {
    axios.get(appConfig.baseApiUrl + "auth/resendlogincode")
      .then((resJson: any) => {
        if (resJson.data.statusCode === 200) {
          alertify.success("SMS gönderildi!");
          this.setState({ resendCodeToggle: false, resendCodeError: false });

          this.StartRemainingTimeofReSentCode();
        }
        else {
          alertify.error(resJson.response.data.data)
        }
      }).catch((error) => {
        this.SetTrueErrorResendCode();

        if(error.isAxiosError){
          alertify.error("Bir hatayla karşılaştık, Lütfen yeniden deneyin!");
          return;
        }
        alert(error.response.data.data);        
      });
    // .finally(() => {
    //   this.StartRemainingTimeofReSentCode();
    // });
  }
  StartRemainingTimeofReSentCode() {
    console.log("Yeni kod sayacı başlatıldı.");
    var timeout = setInterval(() => {
      this.setState(prevState => {
        console.dir(prevState);
        return {
          resendCodeTime: prevState.resendCodeTime - 1,
        };
      });

      var minute = Math.floor(this.state.resendCodeTime / 60);
      var second = this.state.resendCodeTime % 60;
      var secondStr = second < 10 ? `0${second}` : `${second}`;

      var time = `0${minute} : ${secondStr}`
      this.setState({ resendCodeTimeStr: time });
      if (this.state.resendCodeTime <= 0) {
        clearTimeout(timeout);
        this.setState({ resendCodeToggle: true, resendCodeError:true });
      }
    }, 1000);
  }

  getResendCodeButton() {
    if (this.state.resendCodeError) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="d-flex justify-content-center">
        {
          this.state.resendCodeToggle ?
            <a href="#" className="link-primary"
              onClick={(event) => { event.preventDefault(); this.ReSendCode(); }}>
              Yeni kod gönder</a> :
            <span> {this.state.resendCodeTimeStr}</span>
        }
      </div>
    )
  }
  render() {
    if (this.state.isLoggedIn === true) {
      return <Redirect to="/home" />;
    } else {
      return (
        <div className="login-main">
          <Container className="login-main-container">
            <Row className="justify-content-md-center">
              <Form>
                <Image src={logo} fluid style={{ width: "300px" }} />
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Doğrulama Kodu</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sms ile gelen kodu giriniz."
                    onChange={this.handleChange}
                    name="code"
                  />
                </Form.Group>
                {this.getResendCodeButton()}
                {this.sendCodeButton()}
              </Form>
            </Row>
          </Container>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    usercontext: state.loginReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login2fa: bindActionCreators(loginActions.login2fa, dispatch),
      setIsChangedPassword: bindActionCreators(changePasswordActions.setIsChangedPassword, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateTwoFactor);
