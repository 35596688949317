import React, { Component } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo from "../../assets/istanbul.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginActions from "../../store/actions/loginActions";
import * as changePasswordActions from "../../store/actions/changePasswordActions";
import { Redirect, Link } from "react-router-dom";
import alertify from "alertifyjs";
import container from "../../ioc/IocContainer";
import IAuthService from "../../interfaces/ServiceInterfaces/IAuthService";
import TYPES from "../../ioc/types";
import HttpStatusCode from "../../common/HttpStatusCode";

class Login extends Component<any, any> {
  authService = container.get<IAuthService>(TYPES.IAuthService);
  _isMounted = false;
  state = {
    username: "",
    password: "",
    isRememberme: false,
    isLoading: false,
    isLogin: false
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = event => {
    let name = event.target.name;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [name]: value
    });
  };
  async login() {

    this.setState({ isLoading: true });
    await this.authService
      .login(this.state)
      .then((myJson: any) => {
        if (myJson.isAxiosError) {
          alertify.error(myJson.message);
          return;
        }
        else if(myJson.response?.isAxiosError){
          alertify.error(myJson.response.data.ErrorMessage);
          return;
        }
        
        if (myJson.data?.isSuccessful) {
          alertify.success("Giriş Başarılı");
          alert(myJson.data.data.messages[0]); //Silinecek
          //console.dir(myJson.data.responseData);
          // this.props.actions.login(myJson.data.responseData);
          // this.props.actions.setIsChangedPassword(false);
          // localStorage.setItem("userContext", JSON.stringify(myJson.data.responseData));
          if (this._isMounted) this.setState({ isLogin: true });
        }
        else if (myJson.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
          alertify.error(myJson.response.data.ErrorMessage);
          return;
        }
        else if (myJson.response.status === HttpStatusCode.UNAUTHORIZED) {
          myJson.response.data.exception.errors.forEach(x => alertify.error(x));
          return;
        }
        else
          alertify.error("Bir hata alındı.");

      })
      .catch((error) => {
        alertify.error(error.message);
      })
      .finally(() => {
        if (this._isMounted) this.setState({ isLoading: false });
      });
  }

  getLoginButton() {
    if (!this.state.isLoading)
      return (
        <Button variant="primary" type="button" onClick={() => this.login()}>
          Login
        </Button>
      );
    else
      return (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Login
        </Button>
      );
  }

  getForgettenPasswordButton() {
    return (
      <div className="d-flex justify-content-center">
        &nbsp; <Link to="/forgettenPassword">Şifreni mi Unuttun?</Link>
      </div>
    );
  }

  render() {
    if (this.state.isLogin === true) {
      return <Redirect to="/authenticate-2fa" />;
    }
    return (
      <div className="login-main">
        <Container className="login-main-container">
          <Row className="justify-content-md-center">
            <Form>
              <Image src={logo} fluid style={{ width: "300px" }} />
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Kullanıcı Adı</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kullanıcı adı giriniz."
                  onChange={this.handleChange}
                  name="username"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Şifre</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Şifre giriniz."
                  onChange={this.handleChange}
                  name="password"
                />
              </Form.Group>
              {this.getForgettenPasswordButton()}
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  name="isRememberme"
                  type="checkbox"
                  label="Beni hatırla!"
                  onChange={this.handleChange}
                />
              </Form.Group>
              {this.getLoginButton()}
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
