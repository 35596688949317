import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { DownloadExcel } from "../../services/ExcelService";
import { connect } from "react-redux";
import axios from "axios";
import appConfig from "../../config/appConfig";
import alertify from "alertifyjs";

function UserLogs() {
  const tableRef = React.createRef();
 
  return (
    <MaterialTable
      title="Kullanıcı Kayıtları"
      tableRef={tableRef}
      columns={[
        { title: "Mesajlar", field: "message" },
        { title: "Tarih", field: "reportDate", type: 'datetime' },
        { title: "Kim", field: "createdBy", },
      ]}
      // data={logs}
      data={query =>
        new Promise((resolve, reject) => {
          let url = 'logs/GetLogs?page=' + (query.page + 1);
          axios.get(appConfig.baseApiUrl + url).then((resJson: any) => {
            if(resJson.data.success){
              resolve({
              data: resJson.data.data,
              page: query.page,
              totalCount: resJson.data.totalItemCount,
            });
            return;
            }
            throw new Error("bir hata oluştu");
          }).catch((error)=>{
            alertify.error(error.message);
          });
        })
      }
      localization={{
        pagination: {
          labelDisplayedRows: "{from}-{to} of {count}",
        },
        body: {
          emptyDataSourceMessage: "No records to display",
          filterRow: {
            filterTooltip: "Filter",
          },
        },
        toolbar: {
          searchTooltip: "Ara",
          searchPlaceholder: "Aranacak kelimeyi giriniz",
        },
      }}
      options={{
        sorting: true,
        search: true,
        searchFieldAlignment: "right",
        paging: true,
        pageSizeOptions: [10],
        pageSize: 10,
        paginationType: "normal",
        showFirstLastPageButtons: true,
        showTextRowsSelected: false,
        // paginationPosition: "both",
        exportAllData: true,
        actionsColumnIndex: -1,
        selection: false,
        columnsButton: false,
        toolbar:true,
      }}
      actions={[
        {
          icon: 'refresh',
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => tableRef.current && (tableRef.current as any).onQueryChange(),
        }
      ]}
    />
  );
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogs);