import React, { useState, useEffect } from "react";
import {
  Grid,
  FormGroup,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import { Formik } from "formik";
import MaterialTable from "material-table";
import alertify from "alertifyjs";
import axios from "axios";
import appConfig from "../../config/appConfig";
import { DownloadExcel } from "../../services/ExcelService";
import * as consts from "../../common/consts";
import * as icons from "@material-ui/icons";

const roles = [
  {
    id: consts.ROLE_ADMIN,
    roleName : "Admin"
  },
  {
    id: consts.ROLE_CREATE_CUSTOMER,
    roleName : "Müşteri Oluşturma"
  },
  {
    id: consts.ROLE_EDIT_CUSTOMER,
    roleName : "Müşteriyi Güncellme"
  },
  {
    id: consts.ROLE_SHOW_CUSTOMER_LIST,
    roleName : "Müşteriyi Bilgilerini Görüntüleme"
  },
  {
    id: consts.ROLE_SAVE_CUSTOMER_GROUP,
    roleName : "Müşteri Grubu Oluşturma"
  },
  {
    id: consts.ROLE_SHOW_CUSTOMER_GROUP,
    roleName : "Müşteri Grupları Görüntüleme"
  },
  {
    id: consts.ROLE_SHOW_CUSTOMER_ACCOUNTS,
    roleName : "Müşterinin Hesapları Görüntüleme"
  },
  {
    id: consts.ROLE_EXCHANGE_TRANSFER_WITH_RATE,
    roleName : "Kurlu Giriş/Çıkış Yapma"
  },
  {
    id: consts.ROLE_MAKE_MONTH_END_PROCESS,
    roleName : "Hesap Kesim Işlemi Yapma"
  },
  {
    id: consts.ROLE_ADD_DAILY_PROFIT,
    roleName : "Günlük Kar Girme"
  },
  {
    id: consts.ROLE_ADD_SHOP_PROFIT,
    roleName : "Dükkan Karı Girme"
  },
  {
    id: consts.ROLE_GET_CUSTOMER_TRANSACTION_HISTORY,
    roleName : "Müşteri̇ Raporlarını Görüntüleme"
  },
  {
    id: consts.ROLE_GET_GROUP_SUMMARY_REPORT,
    roleName : "Grup Raporlarını Görme"
  },
  {
    id: consts.ROLE_GET_GENERAL_TRANSACTION_HISTORY,
    roleName : "Günlük Kar Tablosunu Görme"
  },
  {
    id: consts.ROLE_GENERATE_SMS_REPORT,
    roleName : "SMS Raporu Oluşturma"
  }
]

const phoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export default function UserList() {
  const [users, setusers] = useState([]);
  const initialUser = {
    id: 0,
    nameSurname: "",
    username: "",
    email:"",
    phone:"",
    isActive: true,
    cantSeeBalance:false,
    password: "",
    roles: [],
    rolesStr: "",
  };
  const [selectedUser, setselectedUser] = useState(initialUser);

  useEffect(() => {
    axios.get(appConfig.baseApiUrl + "users/GetAll").then((resJson) => {
      setusers(resJson.data);
    });
  }, []);

  return (
    <Grid container>
      <Grid item md={3} xs={12}>
        <Formik
          initialValues={{
            id: selectedUser.id,
            nameSurname: selectedUser.nameSurname,
            username: selectedUser.username,
            email: selectedUser.email,
            phone: selectedUser.phone,
            isActive: selectedUser.isActive,
            password: selectedUser.password,
            cantSeeBalance:selectedUser.cantSeeBalance,
            roles: selectedUser.roles,
            rolesStr: selectedUser.rolesStr,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          validate={(values) => {
            
            const errors = {} as any;
            if (!values.nameSurname)
              errors.nameSurname = "Lütfen kullanicinin ismini ve soyismini giriniz!"
            if (!values.username)
              errors.username = "Lütfen kullanici ismini giriniz!"
            if (!values.email)
              errors.email = emailRegex.test(values.email) ? "" : "Lütfen geçerli e-mail giriniz!"
            if (!values.phone)
              errors.phone = phoneRegex.test(values.phone) ? "" : "En az 10 rakam gereklidir!"
          
            return errors;
            
          }}
          onSubmit={(values, actions) => {
            axios
              .post(appConfig.baseApiUrl + "users/SaveUser", {...values, rolesStr:values.roles.join(",")})
              .then((resJson) => {
                if (resJson.data.responseCode === 200) {
                  setusers(resJson.data.responseData);
                  setselectedUser(initialUser);
                  alertify.success("İşlem Başarılı!");
                }
                else{
                    alertify.error(resJson.data.responseMessage)
                }
              });
          }}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit} style={{ maxWidth: 250 }}>
              <FormGroup  style={{ maxWidth: 250 }}>
                <TextField
                  name="nameSurname"
                  label="Ad Soyad"
                  value={formProps.values.nameSurname}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.nameSurname && {error:true,helperText:formProps.errors.nameSurname})}
                  
                />
                <TextField
                  name="username"
                  label="Kullanıcı Adı"
                  value={formProps.values.username}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.username && {error:true,helperText:formProps.errors.username})}

                />
                <TextField
                  name="email"
                  label="Email"
                  value={formProps.values.email}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.email && {error:true,helperText:formProps.errors.email})}

                />
                <TextField
                  name="phone"
                  label="Telefon Numarası"
                  value={formProps.values.phone}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  InputLabelProps={{ shrink: true }}
                  {...(formProps.errors.phone && {error:true,helperText:formProps.errors.phone})}

                />
               

                  <InputLabel id="userRoles-label" shrink={true}>
                    Kullanici Rolleri
                  </InputLabel>
                  <Select
                  style={{ maxWidth: 250 }}
                    multiple
                    type="select"
                    labelId="userRoles-label"
                    name="roles"
                    value={formProps.values.roles}
                    onChange={formProps.handleChange}
                  >
                    {roles.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {item.roleName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                    
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isActive"
                      value={formProps.values.isActive}
                      checked={formProps.values.isActive}
                      onChange={formProps.handleChange}
                    />
                  }
                  label="Aktif mi ?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="cantSeeBalance"
                      value={formProps.values.cantSeeBalance}
                      checked={formProps.values.cantSeeBalance}
                      onChange={formProps.handleChange}
                    />
                  }
                  label="Bakiye Göremez ?"
                />
                <Button color="primary" type="submit">
                  Kaydet
                </Button>
              </FormGroup>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item md={9} xs={12}>
        <MaterialTable
          title="Grup Listesi"
          columns={[
            { title: "Id", field: "id" },
            { title: "Adı Soyadı", field: "nameSurname" },
            { title: "Aktif", field: "isActive" ,lookup: { true: <div> <icons.Done/></div>, false: <div> <icons.Close/></div> }},
            { title: "Admin", field: "isAdmin" ,lookup: { true: <div> <icons.Done/></div>, false: <div> <icons.Close/></div> }},
            { title: "Bakiyeyi Görebilir", field: "cantSeeBalance", lookup: { false: <div> <icons.Done/></div>, true: <div> <icons.Close/></div> } },
          ]}
          data={users}
          actions={[
            {
              icon: "edit",
              tooltip: "Kullanıcı Güncelle",
              onClick: (event, rowData) => {
                setselectedUser(rowData as any);
              },
            },
            {
              icon: "add",
              tooltip: "Kullanıcı Ekle",
              isFreeAction: true,
              onClick: (event) => {setselectedUser(initialUser)},
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            exportButton: true,
            exportCsv: (columns, data) => {
              DownloadExcel("Kullanıcı Listesi", columns, data);
            },
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} of {count}",
            },
            header: {
              actions: "İşlemler",
            },
            body: {
              emptyDataSourceMessage: "No records to display",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
            toolbar: {
              // showColumnsTitle?: string;
              // showColumnsAriaLabel?: string;
              exportTitle: "Dosyaya Aktar",
              // exportAriaLabel?: string;
              exportAriaLabel: "Excel'e Aktar",
              searchTooltip: "Ara",
              searchPlaceholder: "Aranacak kelimeyi giriniz",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
