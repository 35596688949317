import * as actionTypes from "../actions/actionTypes";
import initialStates from './initialStates';
import jwt_decode from "jwt-decode"; 

const loginReducer = (state = initialStates.userContext, action) => {
  let newState = state;
  switch (action.type) {
    case actionTypes.PRE_LOGIN:
      //TO DO: ilgilenilecek burası saçma
      localStorage.setItem("cookie", action.payload);
      return (newState = action.payload);
    case actionTypes.LOGIN:
        localStorage.setItem("userContext",JSON.stringify( action.payload));
        return (newState = action.payload);
    case actionTypes.LOGOUT:
      localStorage.removeItem("userContext");
      return (newState = {});
    case actionTypes.SET_LOGIN_CONTEXT:
      return (newState = action.payload);
    default:
      return newState;
  }
};

export default loginReducer;
