import * as actionTypes from "../actions/actionTypes";
import initialStates from './initialStates';

const changePasswordReducer = (state = initialStates.isChangedPassword, action) => {
  let newState = state;
  switch (action.type) {
    case actionTypes.SET_IS_CHANGED_PASSWORD:
      return (newState = action.payload);
    default:
      return newState;
  }
};

export default changePasswordReducer;