import React, { useEffect, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import appConfig from "../../config/appConfig";
import { connect } from "react-redux";
import alertify from "alertifyjs";

function CustomerRequestList(props) {
  const [transactions, setTransactions] = useState([]);

  const [tableProps, setTableProps] = useState({
    data: [],
    isLoading: true,
    page: 0,
    totalCount: 0
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [querySearch, setQuerySearch] = useState('');

  // const getTransactions = () => {
  //   axios
  //     .get(appConfig.baseApiUrl + "customer/GetCustomerRequestList")
  //     .then((resJson) => {
  //       setTransactions(resJson.data);
  //     });
  // };

  // useEffect(() => {
  //   getTransactions();
  // }, []);
  const loadData = () => {
    setTableProps({
      ...tableProps,
      isLoading: true,
    })

    let url = 'customer/GetCustomerRequestList?page=' + (currentPage + 1);
    axios.get(appConfig.baseApiUrl + url).then((resJson: any) => {
      if (resJson.data.success) {
        setTableProps({
          data: resJson.data.data.filter(function (obj) {
            return Object.keys(obj).some(function (key) {
              return obj[key] ? (obj[key]).toString().toLowerCase().includes(querySearch.toLowerCase()) : false
            })
          }),
          isLoading: false,
          page: currentPage,
          totalCount: resJson.data.totalItemCount
        });
        return;
      }
      throw new Error("bir hata oluştu");
    }).catch((error) => {
      alertify.error(error.message);
    });
  };
  useEffect(() => {
    var delay = setTimeout(() => {
      loadData();
    }, 500);
    return () => clearTimeout(delay);
  }, [querySearch, currentPage]);


  return (
    <div>
      <MaterialTable
        title="Müşteri Açma Talep Listesi"
        onSearchChange={setQuerySearch}
        columns={[
          { title: "Müşteri Adı", field: "customerName" },
          { title: "Tutar", field: "amount" },
          { title: "işlem Tarihi", field: "transactionDate", type: "date" },
          { title: "Döviz", field: "currency.currencyCode" },
          { title: "Statü", field: "status.statusName" },
        ]}
        onChangePage={setCurrentPage}
        // data={query =>
        //   new Promise((resolve, reject) => {
        //     let url = 'customer/GetCustomerRequestList?page=' + (query.page + 1);
        //     axios.get(appConfig.baseApiUrl + url).then((resJson: any) => {
        //       if(resJson.data.success){debugger;
        //         resolve({
        //         data: resJson.data.data,
        //         page: query.page,
        //         totalCount: resJson.data.totalItemCount,
        //       });
        //       return;
        //       }
        //       throw new Error("bir hata oluştu");
        //     }).catch((error)=>{
        //       alertify.error(error.message);
        //     });
        //   })
        // }
        actions={[
          {
            icon: "check",
            tooltip: "Onayla",
            onClick: (event, rowData: any) => {
              console.dir(event);
              alertify.confirm(
                "Onay",
                "Müşteri Kabul Ediliyor. Bu işlemi onaylıyor musunuz?",
                function () {
                  axios
                    .post(
                      appConfig.baseApiUrl +
                      "Customer/ApproveCustomerRequest?id=" + rowData.id
                    )
                    .then((resJson) => {
                      console.dir(alertify);
                      if (resJson?.data) {
                        loadData();
                        alertify.success("İşlem Onaylandı!");
                      } else
                        alertify.error("İşlem Yapılamadı!");

                    }).catch((error) => alertify.error(error.message));;
                },
                function () {
                  alertify.warning("İptal Edildi");
                }
              );
            },
          },
          {
            icon: "clear",
            tooltip: "Reddet",
            onClick: (event, rowData: any) => {
              alertify.confirm(
                "İptal",
                "Müşteriyi Reddediyorsunuz. Bu işlemi onaylıyor musunuz?",
                function () {
                  axios
                    .post(
                      appConfig.baseApiUrl +
                      "Customer/RejectCustomerRequest?id=" + rowData.id
                    )
                    .then((resJson) => {
                      if (resJson?.data) {
                        loadData();
                        alertify.success("İşlem Reddedildi!");
                      } else
                        alertify.error("İşlem Yapılamadı!");
                    }).catch((error) => alertify.error(error.message));
                },
                function () {
                  alertify.warning("İptal Edildi");
                }
              );
            },
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => loadData(),
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          pageSizeOptions: [10],
          pageSize: 10,
          sorting: true,
          search: true,
          searchFieldAlignment: "right",
          paging: true,
          paginationType: "normal",
          showFirstLastPageButtons: true,
          showTextRowsSelected: false,
          // paginationPosition: "both",
          exportAllData: true,
          selection: false,
          columnsButton: false,
          toolbar: true,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
          },
          header: {
            actions: "İşlemler",
          },
          body: {
            emptyDataSourceMessage: "No records to display",
            filterRow: {
              filterTooltip: "Filter",
            },
          },
          toolbar: {
            exportTitle: "Dosyaya çıkar",
            searchTooltip: "Ara",
            searchPlaceholder: "Aranacak kelimeyi giriniz",
          },
        }}
        {...tableProps}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoading: state.loadingReducer,
    totalRequest: state.totalRequestReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRequestList);
